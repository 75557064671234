import { render, staticRenderFns } from "./InstagramBlock.vue?vue&type=template&id=1c11164e&scoped=true&"
import script from "./InstagramBlock.vue?vue&type=script&lang=js&"
export * from "./InstagramBlock.vue?vue&type=script&lang=js&"
import style0 from "./InstagramBlock.vue?vue&type=style&index=0&id=1c11164e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c11164e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiLazyImg: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiLazyImg.vue').default,UiSwiperCarousel: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiSwiperCarousel.vue').default})
